
import React from "react"
import dayjs from 'dayjs'
import {
    Card, Row, Statistic, Col, Button, DatePicker, message, Popconfirm, Select,
    Input, Form, Divider, Modal, Alert, Tag
} from 'antd'
import { Comment } from '@ant-design/compatible';
import { EditTwoTone, CommentOutlined, ApiTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import { CirclePicker, } from 'react-color';
import UserTags from './UserTags'
import InformationTabs from './InformationTabs';
import './UserInformation.css'
import UserAvatar from "./UserAvatar";

import { connect } from "react-redux"

import RequireRolesOr from '../require_roles_or'
import { Popover } from "antd";

const { Countdown } = Statistic;
const { TextArea } = Input;

class UserInformation extends React.Component {
    state = {
        noteContent: "",
        noteSubmitting: false,
        vipEndAtFormVisible: false,
        vipEndAtValue: null,
        channel: null,
        // 系统中的渠道数据，用于修改用户渠道
        channels: [],
        user: {},
        financeOrders: [],
        signInLogs: [],
        notePagedData: [],
        // 评论选的颜色
        commentSelectColor: false,
        tags: [],
        id: null
    }

    userId(nextProps) {
        return nextProps?.match?.params?.id || nextProps.id
    }

    componentDidMount() {
        this.channelJoin(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const user_id = this.userId(nextProps)
        if (user_id !== this.state.id) {
            !!this.state.channel && this.state.channel.leave()
            this.channelJoin(nextProps)
        }
    }

    channelJoin(nextProps) {
        let props = nextProps || this.props
        const user_id = this.userId(props)
        const channel = props.socket.channel(`ops:user_information:${user_id}`)
        channel.on("data", ({ user, finance_orders, tags }) => this.setState({ user, financeOrders: finance_orders, tags }))
        channel.on("channel-data", ({ data }) => this.setState({ channels: data }))

        channel.join()
        this.setState({ channel, id: user_id })
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    get UIVipEndAt() {
        return <div>VIP截止日
            <RequireRolesOr require={["acupoint:role::customer-service"]}>
                <Button onClick={() => this.handleVipEndFormOpen()} size="small" shape="circle" type="link" icon={<EditTwoTone />} />
            </RequireRolesOr>
        </div >
    }

    get StatusView() {
        let style = { transform: "translate(0px, -2px)" }
        switch (this.state?.user?.status) {
            case "normal":
                return <Tag color="blue" style={style}>正常</Tag>
            case "revoke":
                return <Tag color="red" style={style}>已注销</Tag>

            default:
                return <Tag color="gold">未知</Tag>
        }
    }

    // 当评价页数变更时执行
    handleNotePageChange(page, pageSize) {
        this.pushEvent("note-list", { page, page_size: pageSize })
    }

    handleNoteSubmit() {
        // this.setState({ noteSubmitting: true })
        this.pushEvent("note-add", { content: this.state.noteContent, color: this.state.commentSelectColor })
            .receive("ok", (reply, ref) => {
                this.setState({ noteContent: "", noteSubmitting: false });
                message.success("评价成功")
            })
    }

    // vip日期框的值变化
    handleVipEndAtChange(date, dateStr) {
        this.setState({ vipEndAtValue: dateStr })
    }

    // 注销会员资格
    handleVipEndFormRevoke() {
        this.pushEvent("vip-end-at-revoke", { user_id: this.state.id })
            .receive("ok", ({ success, message: msg }) => {
                if (success) {
                    message.success("注销成功")
                    this.handleVipEndFormClose()
                } else message.error(msg)
            })
    }

    // vip值的提交
    handleVipEndFormSubmit() {
        this.pushEvent("vip-end-at-submit", { user_id: this.state.id, vip_end_at: this.state.vipEndAtValue })
            .receive("ok", ({ success, message: msg }) => {
                if (success) {
                    message.success("提交成功")
                    this.handleVipEndFormClose()
                } else message.error(msg)
            })
    }

    // vip修改的表单关闭
    handleVipEndFormClose() {
        this.setState({ vipEndAtFormVisible: false })
    }

    // vip修改的开启
    handleVipEndFormOpen() {
        this.setState({ vipEndAtFormVisible: true })
    }

    // 踢下线用户
    handleKickOut(form) {
        Modal.confirm({
            title: '踢用户下线',
            content: <>
                <Alert message="若用户不在线则没有实际效果" banner type="warning" showIcon />
                <p></p>
                <Input placeholder="给用户的消息，留空则不提示" maxLength={20} onChange={(e) => this.setState({ kick_out_message: e.target.value })} />
            </>,
            onOk: (close) => {
                this.props.customerOperationChannel.push("kick-out", { user_id: this.state.id, message: this.state.kick_out_message })
                    .receive("ok", () => {
                        this.setState({ kick_out_message: null })
                        close();
                        message.success("命令已成功")
                    })
                return false
            }
        });
    }

    editChannelFormRef = React.createRef()
    // 修改用户渠道
    handleChangeChannel() {
        Modal.confirm({
            width: 400,
            title: '修改渠道',
            content: <Form ref={this.editChannelFormRef} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Form.Item label="渠道" name="channel" initialValue={this.state.user.nt_channel?.replace("ReleaseChannel.", "")} rules={[{ required: true, message: '必填' }]}>
                    <Select showSearch optionLabelProp="label" options={this.state.channels.map(item => {
                        return { label: item.name, key: item.key, value: item.key }
                    })}></Select>
                </Form.Item>
            </Form>,
            onCancel: () => {
                this.editChannelFormRef.current.resetFields();
            },
            onOk: (close) => {
                const form = this.editChannelFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        this.state.channel.push("change-channel", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 重新载入
    handleReload() {
        this.pushEvent("data", {})
    }



    render() {
        const { user, financeOrders, signInLogs, notePagedData } = this.state;
        return !!this.state.user?.id && <>
            <RequireRolesOr require={["acupoint:role::customer-service"]}>
                <Modal centered onCancel={this.handleVipEndFormClose.bind(this)} title="修改VIP截止日"
                    open={this.state.vipEndAtFormVisible} width={300}
                    footer={<div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Popconfirm title="确定注销？" onConfirm={this.handleVipEndFormRevoke.bind(this)}>
                            <Button key="revoke" size="small" type="primary" danger>
                                注销VIP资格
                            </Button>
                        </Popconfirm>
                        <span>
                            <Button key="cancel" size="small" onClick={this.handleVipEndFormClose.bind(this)}>
                                取消
                            </Button>
                            <Button key="submit" size="small" type="primary" onClick={this.handleVipEndFormSubmit.bind(this)}>
                                修改
                            </Button>
                        </span>
                    </div>}
                >
                    <DatePicker disabledDate={(date) => date.isBefore(dayjs())}
                        onChange={this.handleVipEndAtChange.bind(this)}
                        defaultValue={user.vip_ended_at && dayjs(user.vip_ended_at)} />
                </Modal>
            </RequireRolesOr>

            <Card className={`user-information-card ${this.props.inModal ? "in-modal" : ""}`} bordered={!this.props.inModal}>
                <Row gutter={16}>
                    <Col span={2} style={{ "display": "flex", "justifyContent": "flex-start", alignItems: "center", "paddingTop": 10, "flexDirection": "column" }}>
                        <UserAvatar size={80} avatar={user.avatar} id={user.id} onlyAvatar disabled vip={user.group === "vip"} />
                        <br />

                        <div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Divider plain style={{ color: "silver" }}>个人信息</Divider>
                        <h1 className="meta" style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className="title">{user.nickname} <span style={{ color: "silver", fontSize: 18 }}>@ {user.id} {this.StatusView}</span></span>
                            <span className="action">
                                <RequireRolesOr require={["acupoint:role::operator"]}>
                                    <span className="kick-out" onClick={this.handleKickOut.bind(this)}><ApiTwoTone twoToneColor="red" /></span>
                                </RequireRolesOr>
                                <span className="customer-service" onClick={() => this.handleTabChange("customer-service")}><CommentOutlined /></span>
                            </span>
                        </h1>

                        <Row>
                            <Col span={12}>
                                <Countdown title="VIP剩余" value={user.vip_ended_at} format="Y 年 M 月 D 天" />
                            </Col>
                            <Col span={12}>
                                <Statistic title={this.UIVipEndAt} value={(user.vip_ended_at && dayjs(user.vip_ended_at).format("YYYY 年 MM 月 DD 日")) || "无"} />
                            </Col>
                        </Row>
                        <br />
                        {user.login_app_info ?
                            <Row>
                                <Col span={6}>
                                    <Statistic title="手机品牌" value={user.login_app_info?.device_brand || "Apple"} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="手机型号" value={user.login_app_info?.device_model} />
                                </Col>
                                <Col span={12}>
                                    <Statistic title="系统版本" value={user.login_app_info?.device_version} />
                                </Col>
                            </Row> : null}
                        <br />
                        {user.login_app_info ?
                            <Row>
                                <Col span={6}>
                                    <Statistic title={
                                        <div>商业渠道<RequireRolesOr require={["acupoint:role::customer-service"]}>
                                            <Button size="small" onClick={this.handleChangeChannel.bind(this)} shape="circle" type="link" icon={<EditTwoTone />} />
                                        </RequireRolesOr></div>
                                    } value={`${user.nt_channel?.replace("ReleaseChannel.", "")}`} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="当前应用版本" value={user.version} />
                                </Col>
                                <Col span={12}>
                                    <Statistic title="激活时间" value={dayjs(user.active_at).format("YYYY-MM-DD HH:mm")} />
                                </Col>
                            </Row> : null}
                        <br />
                        {user.summary ?
                            <Row>
                                <Col span={6}>
                                    <Statistic title="短信凭据" formatter={value => value || null} value={user.summary?.sms?.phone} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="微信凭据" valueStyle={{ color: "green", textIndent: "6px" }} formatter={value => value !== 0 ? <CheckCircleOutlined /> : null} value={user.summary?.wechat?.group} />
                                </Col>
                                <Col span={12}>
                                    <Statistic title="Apple ID凭据" valueStyle={{ color: "green", textIndent: "6px" }} formatter={value => value !== 0 ? <CheckCircleOutlined /> : null} value={user.summary?.appleid?.aud} />
                                </Col>
                            </Row> : null}

                        <Divider plain style={{ color: "silver" }}>标签</Divider>
                        <UserTags tags={this.state.tags} pushEvent={this.pushEvent.bind(this)} />

                        <Divider plain style={{ color: "silver" }}>评价</Divider>
                        <Comment
                            content={
                                <>
                                    <Form.Item>
                                        <CirclePicker width="100%" circleSize={24} color={this.state.commentSelectColor || false} onChangeComplete={(color) => this.setState({ commentSelectColor: color })} />
                                    </Form.Item>
                                    <Form.Item>
                                        <TextArea rows={4} value={this.state.noteContent} onChange={(e) => this.setState({ noteContent: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" type="primary" loading={this.state.noteSubmitting} onClick={this.handleNoteSubmit.bind(this)}>
                                            添加
                                        </Button>
                                    </Form.Item>
                                </>
                            }
                        />
                    </Col>
                    <Col span={10}>
                        {!!this.state.channel &&
                            <InformationTabs
                                tabKey={this.props.tabKey}
                                handleTabChange={(c) => this.handleTabChange = c}
                                userId={this.userId(this.props)}
                                financeOrders={financeOrders} signInLogs={signInLogs}
                                notePagedData={notePagedData}
                                reload={this.handleReload.bind(this)}
                                pushEvent={this.pushEvent.bind(this)} />}
                    </Col>
                </Row>
            </Card >
        </>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        customerOperationChannel: global.customerOperationChannel
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);