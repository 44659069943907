import React from "react";
import { Button, Image, Divider, Tooltip, Popover } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { BarcodeOutlined, ClockCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, FileImageOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import UserAvatar from "../../components/user/UserAvatar";
import Config from "../../config";


function InviteCodeItem({ hideAvatar = false, inviteCode, onHandleClickDetail, onHandClickSim }) {
    return <div style={{
        margin: "0 auto",
        width: 295,
        height: 123,
        backgroundImage: `url(${Config.ResourceUrl(inviteCode.plan?.data?.app_banner_bg_path)})`, backgroundRepeat: "no-repeat", backgroundSize: "contain",
        position: "relative",
        padding: 20
    }}>
        <h2>{inviteCode.plan.display_name}</h2>
        {hideAvatar ? null : <UserAvatar style={{ position: "absolute", top: 20, right: 20 }} nickname={inviteCode.user?.nickname} size={60} avatar={inviteCode.user?.avatar} id={inviteCode.user?.id} vip={inviteCode.user?.group === "vip"} />}
        <div>{dayjs(inviteCode.active_at).format("YYYY-MM-DD")} ~ {dayjs(inviteCode.expired_at).format("YYYY-MM-DD")}</div>
        <div style={{display: "flex", alignItems: "center"}}><img width={40} src={inviteCode.plan.data.coin_logo_url} /> X {inviteCode.coin_balance}</div>
        <div style={{ position: "absolute", bottom: 0, color: inviteCode.plan.data.theme_color.hex }}>
            <Tooltip title={inviteCode.code}><Button icon={<BarcodeOutlined />} style={{ color: inviteCode.plan.data.theme_color.hex }} type="link"></Button></Tooltip>
            {!!onHandleClickDetail && <>
                <Divider type="vertical" />
                <Button style={{ color: inviteCode.plan.data.theme_color.hex }} type="link" onClick={() => onHandleClickDetail(inviteCode.code)}>详情</Button>
            </>}
            {!!onHandClickSim && <>
                <Divider type="vertical" />
                <Button style={{ color: inviteCode.plan.data.theme_color.hex }} type="link" onClick={() => onHandClickSim(inviteCode.code)}>模拟器</Button>
            </>}
        </div>
    </div>
}

export default InviteCodeItem