import React, { useState, useEffect } from "react"
import { Empty } from 'antd'
import InviteCodeItem from "../../../Marketing/components/invite_code_item";
import InviteCodeDetailModel from "../../../Marketing/components/invite_code_detail_modal";

// 当评价页数变更时执行
function handleInviteCodeChange(setData, props, page, pageSize) {
    props.pushEvent("invite-code-list", { page, page_size: pageSize })
        .receive("ok", (data) => {
            setData(data)
        })
}

function InviteCode({ hideAvatar, ...props }) {
    // 记事
    const [inviteCodes, setInviteCodes] = useState([])
    const [showDetailCode, setShowDetailCode] = useState(null)

    useEffect(() => {
        handleInviteCodeChange(setInviteCodes, props)
    }, [props.userId])


    return <div>
        {
            !!inviteCodes.length > 0 ?
                <div>
                    {
                        inviteCodes.map(inviteCode => <div style={{ margin: 10 }}>
                            <InviteCodeItem key={`user-tabs-invite-code-${inviteCode.code}`} hideAvatar={hideAvatar} inviteCode={inviteCode} onHandleClickDetail={(value) => setShowDetailCode(value)} />
                        </div>
                        )
                    }
                </div>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

        <InviteCodeDetailModel code={showDetailCode} close={() => setShowDetailCode(null)} />
    </div >
}

export default InviteCode;