import React from "react";
import { Menu, Tag } from 'antd';
import { RobotOutlined, CoffeeOutlined, FireOutlined, ContactsOutlined, FileExclamationOutlined, DeploymentUnitOutlined, BlockOutlined, NotificationOutlined, FormatPainterOutlined, NodeExpandOutlined, HourglassOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';
import RequireRolesOr from "../../components/require_roles_or";

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="config" title="运营">
            <Menu.Item key="/operation" icon={<CoffeeOutlined />}>
                <Link to="/operation">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>客服服务</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/ops/member" icon={<ContactsOutlined />}>
                <Link to="/ops/member">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>用户库</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/ops/hot-search" icon={<FireOutlined />} >
                <Link to="/ops/hot-search">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>热搜</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/ops/launch-screen" icon={<RobotOutlined />} >
                <Link to="/ops/launch-screen">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>开屏页</span>
                    </div>
                </Link>
            </Menu.Item>


            <Menu.Item key="/ops/graph/suggest" icon={<DeploymentUnitOutlined />}>
                <Link to="/ops/graph/suggest">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>知识图谱"换一换"</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/ops/versions" icon={<BlockOutlined />} >
                <Link to="/ops/versions">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>APP发行版本管理</span>
                        <span><Tag color="blue">实时</Tag></span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <RequireRolesOr require={["acupoint:role::operator"]}>
            <Menu.ItemGroup key="notification" title="消息通知">
                <Menu.Item key="/ops/notification/channel" icon={<NodeExpandOutlined />}>
                    <Link to="/ops/notification/channel">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>频道</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/notification/scene" icon={<FormatPainterOutlined />}>
                    <Link to="/ops/notification/scene">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>场景</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/notification/message" icon={<NotificationOutlined />}>
                    <Link to="/ops/notification/message">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>已发送的消息通知</span>
                            <span><Tag color="blue">实时</Tag></span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>

        <Menu.ItemGroup key="notification" title="用户反馈">
            <Menu.Item key="/ops/feedback/app" icon={<FileExclamationOutlined />} >
                <Link to="/ops/feedback/app">APP反馈</Link>
            </Menu.Item>
            <Menu.Item key="/ops/feedback/diagnosis_sim" icon={<FileExclamationOutlined />} >
                <Link to="/ops/feedback/diagnosis_sim">中济学堂反馈</Link>
            </Menu.Item>
            <Menu.Item key="/ops/feedback/correction" icon={<FileExclamationOutlined />} >
                <Link to="/ops/feedback/correction">纠错</Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <RequireRolesOr require={["acupoint:role::operator"]}>
            <Menu.ItemGroup key="setting" title="配置">
                <Menu.Item key="/ops/runtime_scheduler" icon={<HourglassOutlined />}>
                    <Link to="/ops/runtime_scheduler">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>定时调度</span>
                            <span><Tag color="blue">实时</Tag></span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/giftContent" disabled>
                    <Link to="/ops/settings/giftContent">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>限时优惠</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/phoneNumVerifyRole" disabled>
                    <Link to="/ops/settings/phoneNumVerifyRole">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>手机号码校验正则表达式</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/CustomerServiceNotice" >
                    <Link to="/ops/settings/CustomerServiceNotice">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>客服弹窗提示</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/HealthGuidance" >
                    <Link to="/ops/settings/HealthGuidance">健康咨询</Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/AI-Switcher" >
                    <Link to="/ops/settings/AI-Switcher">AI 诊断开关</Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/qqGroups" disabled>
                    <Link to="/ops/settings/qqGroups">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>点击添加QQ群</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/ops/settings/wechatWaiter" disabled>
                    <Link to="/ops/settings/wechatWaiter">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>微信客服微信ID</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);